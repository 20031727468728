/* =============== GENERAL STYLES =============== */
:root {
    --navy: #0b0b30;
    --gray: #cbd4c2;
    --pink: #ef476f;
    --blue: #2196f3;
    --blueGray: #7392b7;
    font-size: 62.5%;
}

* {
    box-sizing: border-box;
}

body {
    font-family: 'Roboto', sans-serif;
    color: white;
    font-size: 1.6rem;
    overflow-x: hidden;
}

/* =============== APP STYLES ===============*/
.background-pin-container {
    position: relative;
}

.background-pin {
    background-image: url('../../assets/projects-bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
}

.background-pin-inner {
    position: absolute;
    width: 100%;
    top: 0;
}


/* =============== SECTION STYLES =============== */
.main-section {
    padding: 11rem 0;
}

.section-container {
    max-width: 114rem;
    padding: 0 1.5rem;
    margin: 0 auto;
}

.section-title {
    font-size: 4rem;
    margin-bottom: 1.2rem;
    font-weight: 500;
}

.section-border {
    border-top: 3px solid var(--pink);
    width: 10rem;
}

.lead {
    margin-top: 1.2rem;
    color: var(--gray);
    font-size: 2.25rem;
}


/* =============== GLOBAL ANIMATION =============== */
.visible {
    transform: none;
    opacity: 1;
}


/* =============== MEDIA QUERIES =============== */
@media only screen and (max-width: 1200px) {
    .section-container {
        max-width: 100rem;
    }
}

@media only screen and (max-width: 992px) {
    .main-section {
        padding: 7rem 0;
    }

    .section-container {
        max-width: 770px;
    }

    .background-pin {
        background-position: 20%;
    }

    .section-title {
        font-size: 3.5rem;
    }

    .section-border {
        width: 8rem;
    }

    .lead {
        font-size: 2rem;
    }
}

@media only screen and (max-width: 768px) {
    .main-section {
        padding: 5rem 0;
    }

    .section-container {
        max-width: 570px;
    }

    .background-pin {
        background-position: 28%;
    }

    .section-title {
        font-size: 3.25rem;
    }

    .section-border {
        width: 7rem;
    }

    .lead {
        font-size: 1.75rem;
    }
}

@media only screen and (max-width: 576px) {
    .main-section {
        padding: 3rem 0;
    }
    
    .section-container {
        width: 80%;
    }

    .section-title {
        font-size: 2.75rem;
        margin-bottom: 1rem;
    }

    .section-border {
        width: 5rem;
    }

    .lead {
        font-size: 1.5rem;
        margin-top: 0.75rem;
    }

    .background-pin {
        background-position: 35%;
    }
}