/* =============== EDUCATION STYLES =============== */
#education {
    background: black;
    box-shadow: 0 -.3rem 1rem black;
}

.education-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vertical-timeline {
    margin-top: 5rem;
}

.vertical-timeline-element-content {
    border-top: 3px solid var(--blue);
    background-color: #ddd;
    color: black;
    box-shadow: 0 3px 0 #bbb;
}

.vertical-timeline-element-title {
    font-size: 2.2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.vertical-timeline-element-subtitle {
    font-size: 1.6rem;
}

.vertical-timeline-element-border {
    border-top: 3px solid #0005;
    width: 7.5rem;
    margin-top: 1.25rem;
}

.vertical-timeline-element-content p {
    font-size: 1.6rem;
    line-height: 1.3;
    font-weight: 400;
}

.education-interact-link {
    color: var(--pink);
    text-decoration: none;
    font-weight: 500;
    transition: 250ms;
}

.education-interact-link:hover {
    color: #eb1c4d;
}

.vertical-timeline-element-icon {
    background-color: var(--blue);
    color: white;
}

#education .vertical-timeline-element-date {
    color: var(--gray);
    font-size: 1.6rem;
    font-weight: 400;
}


/* =============== MEDIA QUERIES =============== */
@media only screen and (max-width: 1200px) {
    .vertical-timeline {
        margin-top: 3rem;
    }
}

@media only screen and (max-width: 992px) {
    .vertical-timeline {
        margin-top: 2rem;
    }

    #education .vertical-timeline-element-date {
        color: black;
    }
}


@media only screen and (max-width: 576px) {
    .vertical-timeline-element-title {
        font-size: 1.8rem;
    }

    .vertical-timeline-element-subtitle, .vertical-timeline-element-content p, #education .vertical-timeline-element-date {
        font-size: 1.4rem;
    }

    #education .vertical-timeline-element-date {
        padding: 1.25em 0 0 0;
    }
}