/* =============== FOOTER STYLES =============== */
#footer {
    background-color: #111;
    padding: 3rem;   
}

.footer-container {
    text-align: center;
    color: #555e
}


/* =============== MEDIA QUERIES =============== */
@media only screen and (max-width: 768px) {
    #footer {
        padding: 2.5rem;
    }
}

@media only screen and (max-width: 576px) {
    #footer {
        padding: 2rem;
    }
}