.logo-container {
    position: fixed;
    top: 2.5rem;
    left: 6rem;
    width: 6.5rem;
    height: 6.5rem;
    cursor: pointer;
    z-index: 1;
}

.logo {
    width: 100%;
    height: 100%;
    background-image: url('../../assets/logo.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

@media only screen and (max-width: 576px) {
    .logo-container {
        width: 4.5rem;
        height: 4.5rem;
        left: 3rem;
        top: 2rem;
    }
}