/* =============== NAVBAR STYLES =============== */
.navbar {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding: 0 3rem;
}

.navbar-nav li {
    padding: .5rem 0;
}

.nav-link{
    display: block;
    width: 1rem;
    height: 1rem;
    text-indent: -9999px;
    border-radius: 50%;
    border: 2px solid;
    border-color: #777;
    text-decoration: none;
}

.nav-link.active {
    background-color: #777 !important; /* Override hover bg-transparent in media query */
}

.nav-label {
    position: relative;
    right: 300%;
    bottom: 100%;
    width: 100%;
    text-align: right;
    font-size: 1.7rem;
    color: #777;
    opacity: 0;
    transition: 250ms;
}

.nav-link:hover {
    border-color: #444;
    background-color: #444;
}

.nav-link:hover .nav-label {
    opacity: 1;
}

/* =============== MEDIA QUERIES =============== */
@media (hover: none) {
    .nav-link:hover {
        border-color: #777;
        background-color: transparent;
    }

    .nav-link:hover .nav-label {
        opacity: 0;
    }
}